import { createRouter, createWebHistory } from 'vue-router';
import store from './vuex';
// import App from './App.vue';

const routes = [
    {
        path: '/home',
        name: 'home',
        component: () => import('./Home.vue'),
        redirect: '/',
    },
    {
        path: '/signin',
        name: 'signin',
        component: () => import('./auth/Login.vue'),
    },
    {
        path: '/admin',
        component: () => import('./admin/Root.vue'),
        redirect: '/',
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: '/test',
                name: 'test',
                component: () => import('./components/Test.vue'),
            },

            {
                path: "/tours/create",
                name: 'create',
                component: () => import('./components/tours/create/Create.vue'),
                children:[
            
                { path: "/tours/create",
                    name: 'basic',
                    component: () => import('./components/tours/create/Basic.vue'),
                },

                {
                    path: "/tours/create/schedule",
                    name: 'schedule',
                    component: () => import('./components/tours/create/Schedule.vue')
                },

                {
                    path: "/tours/create/policies",
                    name: 'policies',
                    component: () => import('./components/tours/create/Others.vue')
                },

                {
                    path: "/tours/create/pictures",
                    name: 'pictures',
                    component: () => import('./components/tours/create/Pictures.vue')
                },

                { 
                    path: "/tours/create/overview", 
                    name: 'overview',
                    component: () => import('./components/tours/create/Confirmation.vue')
                },
            ]},

            {
                path: "/tours/duplicate",
                name: 'c-create',
                component: () => import('./components/tours/duplicate/Create.vue'),
                children:[
            
                { path: "/tours/duplicate",
                    name: 'c-basic',
                    component: () => import('./components/tours/duplicate/Basic.vue'),
                },

                {
                    path: "/tours/duplicate/schedule",
                    name: 'c-schedule',
                    component: () => import('./components/tours/duplicate/Schedule.vue')
                },

                {
                    path: "/tours/duplicate/policies",
                    name: 'c-policies',
                    component: () => import('./components/tours/duplicate/Others.vue')
                },

                {
                    path: "/tours/duplicate/pictures",
                    name: 'c-pictures',
                    component: () => import('./components/tours/duplicate/Pictures.vue')
                },

                { 
                    path: "/tours/duplicate/overview", 
                    name: 'c-overview',
                    component: () => import('./components/tours/duplicate/Confirmation.vue')
                },
            ]},

            {
                path: "/tours/update/:slug",
                name: 'u-basic',
                component: () => import('./components/tours/update/BasicData.vue'),
                props: true,
            },

            { 
                path: "/tours/update/address/:slug",
                name: 'u-address',
                component: () => import('./components/tours/update/Address.vue'),
                props: true,
            },
            
            { 
                path: "/tours/update/details/:slug",
                name: 'u-details',
                component: () => import('./components/tours/update/Details.vue'),
                props: true,
            },

            { 
                path: "/tours/update/pictures/:slug",
                name: 'u-pictures',
                component: () => import('./components/tours/update/Picture.vue'),
                props: true,
            },

            {
                path: '/tours',
                name: 'tours',
                component: () => import('./components/tours/Tours.vue'),
            },

            {
                path: '/tours/details/:slug',
                name: 'details',
                component: () => import('./components/tours/Details.vue'),
                props: true
            },

            {
                path: '/managers',
                name: 'managers',
                component: () => import('./pages/Manager.vue'),
            },

            {
                path: '/account',
                name: 'account',
                component: () => import('./components/UserAccount.vue'),
            },

            {
                path: '/user',
                name: 'user',
                component: () => import('./pages/User.vue')
            },
            
            {
                path: '/coupon',
                name: 'coupon',
                component: () => import('./pages/Coupon.vue')
            },

            {
                path: '/agencies',
                name: 'agencies',
                component: () => import('./pages/Agencies.vue')
            },
            
            {
                path: '/categories',
                name: 'categories',
                component: () => import('./pages/Category.vue')
            },
            
            {
                path: '/types',
                name: 'types',
                component: () => import('./pages/Types.vue')
            },
            
            {
                path: '/holidays',
                name: 'holidays',
                component: () => import('./pages/Holiday.vue')
            },
            
            {
                path: '/featured',
                name: 'featured',
                component: () => import('./pages/Featured.vue')
            },

            {
                path: '/wallet',
                name: 'wallet',
                component: () => import('./pages/Wallet.vue')
            },

            {
                path: '/withdrawal-request',
                name: 'withdrawal-request',
                component: () => import('./pages/Withdrawal.vue')
            },
            
            {
                path: '/messages',
                name: 'messages',
                component: () => import('./components/MessagesDemo.vue')
            },
            
            {
                path: '/empty',
                name: 'empty',
                component: () => import('./components/EmptyPage.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    
    var loggedIn = false;
    var userData = store.getters.userData;
    if (userData != "") {
        var user = JSON.parse(userData);
        if (user.token != "") {
            window.axios.defaults.headers.common['Authorization'] = "Bearer " + user.token;
            window.axios.defaults.headers.common['Content-Type'] = 'application/json';
            window.axios.defaults.params = {};

            loggedIn = true;
        }
    }
    
    if (to.name !== 'signin') {
        if (!loggedIn) {
            next({
                name: 'signin'
            });
        } else {
            next();
        }
    }  else if (to.name == 'signin') {
        if (userData) {
            next({
                name: from.name
            });
        } else {
            next();
        }
    } else {
        next();
    }

});

export default router;
